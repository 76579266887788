<template>
    <div class="filter-area">
        <div class="filter-title">{{filterTitle}}</div>
        <ul class="filter-list">
            <li>
                <a href="javascript:;"
                    :class="{
                        'selected-filter': filter_value == '0'
                    }"
                    @click="handleClick('0')">
                    全部
                </a>
            </li>
            <li v-for="(item, index) in filterArray"
                :key="index">
                <a href="javascript:;"
                    :class="{
                        'selected-filter': filter_value == item.value
                    }"
                    @click="handleClick(item.value)">
                    {{item.label}}
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                filter_value: '0'
            }
        },
        props: {
            selectValue: {
                type: [ String, Number ],
                default: ''
            },
            filterArray: Array,
            filterTitle: String,
        },
        watch: {
            selectValue: {
                handler: function(value){
                    this.filter_value = value;
                },
                immediate: true
            }
        },
        methods: {
            handleClick(value){
                this.$emit('handleClick', value);
            }
        },
    }
</script>

<style scoped>
.filter-area{
    display: flex;
}
/* 标题 */
.filter-title{
    flex-shrink: 0;
    padding: 2px 0;
}
/* 列表 */
.filter-list{
    flex-shrink: 1;
}
.filter-list > li{
    float: left;
    margin-right: 10px;
}
.filter-list a{
    display: block;
    padding: 2px 16px;
    border-radius: 30px;
    color: #303133;
}
.filter-list a.selected-filter{
    background-color: var(--color);
    color: #fff;
}
</style>